import { useSetState } from 'ahooks'
// import { throttle } from 'lodash-es'
import { useEffect } from 'react'

// 定义断点区间的类型
interface Breakpoints {
  [key: string]: [number, number]
}

// 定义状态的类型
interface State {
  breakpoint: string
  minWidth: number
  maxWidth: number
}

/**
 *@Description: 用于判断目前处于什么size窗口尺寸,监听窗口大小变化并更新断点信息
 *@state {String} breakpoint 返回尺寸的key：xs、sm、md、lg、xl
 */
export default function useResize() {
  const [state, setState] = useSetState<State>({
    breakpoint: '', // 当前断点
    minWidth: 0, // 断点最小宽
    maxWidth: 0 // 断点最小高
  })
  // 定义断点区间配置，表示不同设备类型的宽度范围
  const gridBreakpoints: Breakpoints = {
    xs: [0, 490], // xs phone
    sm: [490, 768], // sm tablet
    md: [768, 1024], // md desktop
    lg: [1024, 1440], // lg desktop
    xl: [1440, Infinity] // xl
  }

  const resizeUpdate = () => {
    const sizeInfo = {
      breakpoint: '',
      minWidth: 0,
      maxWidth: 0
    }
    const width = window.clientWidth || window.innerWidth
    Object.keys(gridBreakpoints).forEach((name) => {
      const item = gridBreakpoints[name]
      if (width >= item[0] && width < item[1]) {
        sizeInfo.breakpoint = name
        sizeInfo.minWidth = item[0]
        sizeInfo.maxWidth = item[1]
      }
    })
    setState({ ...sizeInfo })
  }

  useEffect(() => {
    resizeUpdate()
    window.addEventListener('resize', resizeUpdate)
    return () => {
      window.removeEventListener('resize', resizeUpdate)
    }
  }, [])
  return state
}
