import { useDebounceEffect, useDebounceFn, useSetState } from 'ahooks'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { When } from 'react-if'
import CustomImage from 'src/components/Image'
import useI18n from 'src/hooks/use_i18n'
import { AllCarriersInfoDTO, HandlePageEventProps } from 'src/types'
import { filterCarriersName, getArrayByCountryAndLanguage, sortCarrier } from 'src/utils/carriers_info'
import { toFilterSpace } from 'src/utils/tools'
import CarriersPagination from '../CarriersPagination'
import BsSelect from './bsSelect'
import Styles from './index.module.scss'

// #region component
/**
 *@Description: ResGCountry国家词条资源对应类型定义
 */

interface Props {
  currentPageJson: AllCarriersInfoDTO[]
  currentIdxPage: number
  totalCarriersNum: number
}

interface State {
  currentPage: number
  searchCarriersName?: string
  searchCarriersCountry?: string
  currentPageShowCarriers: number
  searchTotalNum: number
  searchName: any
  searchCountry: any
  searchCountryName: any
  filterText?: string
  carriersArray: AllCarriersInfoDTO[]
  yqCountry: string
  yqResGCarrier: any[]
  loadingState: boolean
}

export default function SearchCarriers({ currentPageJson, currentIdxPage, totalCarriersNum }: Props) {
  const { $t, activeLocale: locale, i18nFormatAll } = useI18n()
  const router = useRouter()
  const itemNameText = locale === 'zh-cn' ? '_name_zh-cn' : locale === 'zh-hk' ? '_name_zh-hk' : '_name'
  const [state, setState] = useSetState<State>({
    currentPage: currentIdxPage || 1,
    currentPageShowCarriers: 24,
    searchTotalNum: totalCarriersNum,
    searchName: router.query.q || '',
    searchCountry: router.query.c || '0',
    searchCountryName: 'Select an option',
    filterText: '', // 获取到国家地区输入框筛选内容
    carriersArray: currentPageJson,
    yqCountry: '',
    yqResGCarrier: [],
    loadingState: true
  })

  const handleOnPageChange = ({ page, eventObject }: HandlePageEventProps) => {
    if (typeof eventObject === 'object' && eventObject.type && typeof eventObject.preventDefault === 'function') {
      // 如果是事件类型，就把原事件取消掉
      eventObject.preventDefault()
    }
    // 先不滚动，后续需要补充放开即可
    // scrollToAnchor({ anchorId: 'carrierSearch', topDeleteNumber: 5 })
    setState({ currentPage: page })
    const query = new URLSearchParams(window.location.search)
    if (page) {
      query.set('page', page.toString())
    } else {
      query.delete('page')
    }
    // 统一修改后格式化string内容
    const newQueryString = query.toString()
    router.push(
      {
        pathname: router.pathname,
        search: `?${newQueryString}`
      },
      undefined,
      { shallow: true }
    )
  }

  // 根据不同类似选项触发更新不同查询运输商状态
  const handleQueryChange = ({ queryKey, value, stateKey }: { queryKey: string; value: any; stateKey?: string }) => {
    try {
      const query = new URLSearchParams(window.location.search)

      stateKey && setState((prevState) => ({ ...prevState, [stateKey]: value }))
      if (value.trim()) {
        query.set(queryKey, value.trim())
        query.set('page', '1')
        if (!query.get('q') && query.get('c') == '0') query.forEach((key) => query.delete(key))
      } else {
        query.delete(queryKey)
      }
      const newQueryString = query.toString()
      router.push(
        {
          pathname: router.pathname,
          search: `?${newQueryString}`
        },
        undefined,
        { shallow: true }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleCarriersInput = (e) => {
    setState({ searchName: e.target.value })
    handleOnPageChange({ page: 1, eventObject: e })
    handleQueryChange({ value: e.target.value, queryKey: 'q' })
  }

  // 针对筛选后的国家列表
  const filteredOptions = useMemo(() => {
    return $t('ResGCountry.items').filter((item) => {
      try {
        return (
          item.key?.toLowerCase().includes(state.filterText) ||
          item._name?.toLowerCase().includes(state.filterText?.toLowerCase())
        )
      } catch (error) {
        return true
      }
    })
  }, [state.filterText])

  useEffect(() => {
    setState({
      yqResGCarrier: window?.ResGCarrier || [],
      yqCountry: window?.YQ?.country || ''
    })
  }, [typeof window])

  useEffect(() => {
    if (router.isReady) {
      const query = new URLSearchParams(router.asPath.split('?')[1])
      setState({
        searchName: query.get('q') || '',
        searchCountry: query.get('c') || '0'
      })
    }
  }, [router.isReady, router.asPath])

  // 用于监听查询参数page的变化更新当前页码数
  useEffect(() => {
    if (router.isReady) {
      const query = new URLSearchParams(router.asPath.split('?')[1])
      const queryPage = Number(query.get('page'))
      if (state.currentPage !== queryPage && !isNaN(queryPage) && queryPage > 0) {
        if (!isNaN(queryPage) && queryPage >= 1 && queryPage <= Math.ceil(state.searchTotalNum / 24)) {
          setState({ currentPage: queryPage })
        } else {
          router.push({ query: '' }, undefined, { shallow: true })
          setState({ currentPage: 1 })
        }
      }
    }
  }, [state.searchTotalNum, router.isReady, router.asPath])

  // 防止更新搜索dom结果太频繁，增加渲染carriersList_dom节点的防抖
  const { run: updateCarrierList } = useDebounceFn(
    (carriersDom) => {
      setState({ carriersArray: carriersDom, loadingState: false })
    },
    {
      wait: 100
    }
  )

  // 用于监听搜索、选择国家等操作，触发更新检索运输商的防抖处理
  useDebounceEffect(
    () => {
      const word = state.searchName.trim()
      const country_iso = state.searchCountry.trim()
      const allCarriersJson = sortCarrier(state.yqResGCarrier, getArrayByCountryAndLanguage(state.yqCountry, locale))
      // 无筛选条件
      if ((!word && country_iso == '0') || !country_iso) {
        const allCarriers = allCarriersJson
        const carriersDom = allCarriers?.slice(
          state.currentPageShowCarriers * (state.currentPage - 1),
          state.currentPage * state.currentPageShowCarriers
        )
        setState({ searchTotalNum: Number(allCarriers?.length), loadingState: true })
        updateCarrierList(carriersDom)
        return
      }

      // iso 匹配的运输商,'0' 代表是全国家
      const isoResult =
        country_iso == '0' ? allCarriersJson : allCarriersJson.filter((item) => item._country_iso === country_iso)

      // text 匹配的运输商
      const textResult = isoResult.filter((item) => {
        const _name = item['_name']?.toUpperCase() || '',
          _name_zh_cn = item['_name_zh-cn']?.toUpperCase() || '',
          _name_zh_hk = item['_name_zh-hk']?.toUpperCase() || '',
          text = word.toUpperCase()
        return _name.includes(text) || _name_zh_cn.includes(text) || _name_zh_hk.includes(text)
      })

      const allCarriers = textResult
      const carriersDom = allCarriers?.slice(
        state.currentPageShowCarriers * (state.currentPage - 1),
        state.currentPage * state.currentPageShowCarriers
      )
      setState({ searchTotalNum: Number(allCarriers?.length), loadingState: true })
      updateCarrierList(carriersDom)
    },
    [
      state.searchName,
      state.searchCountry,
      locale,
      state.yqResGCarrier,
      state.yqCountry,
      state.currentPage,
      state.currentPageShowCarriers
    ],
    { wait: 300 }
  )

  return (
    <>
      <section id={'carrierSearch'} className={classNames(Styles['page-content'])}>
        <div className={classNames(Styles['page-title'])}>
          <h2>{$t('ResCarrierPIndex.global.__carriers')}</h2>
          <a
            href="//res.17track.net/asset/carrier/info/carrier.all.csv"
            title={$t('ResCarrierList.global.__pageIndex_downloadCarrierList')}
            aria-label={$t('ResCarrierList.global.__pageIndex_downloadCarrierList')}
            aria-describedby="download-carriersList"
          >
            <i className={classNames('icon', Styles['fa-download'], 'fa-download')}></i>
            <span id="download-carriersList" className={classNames(Styles['download-title'])}>
              {$t('ResCarrierPIndex.global.__download')}
            </span>
          </a>
        </div>

        <div className={classNames(Styles['yq-search-container'])}>
          <div className={classNames(Styles['form-group'], Styles['form-group-lg'])}>
            <div className={classNames(Styles['input-search'], 'input-group input-group-lg')}>
              <div className="input-group-text">
                <i className={classNames('input-search-icon', Styles['fa-search'], 'fa-search', 'font-size-20')}></i>
              </div>
              <input
                id="yq-search-input"
                type="text"
                className={classNames('form-control')}
                placeholder={$t('ResCarrierPIndex.global.__SearchCarriers')}
                onInput={handleCarriersInput}
                value={state.searchName}
                aria-label={$t('ResCarrierPIndex.global.__SearchCarriers')}
              />
              <button
                id="yq-search-clear"
                type="button"
                aria-label="Close"
                onClick={handleCarriersInput}
                className={classNames(
                  'input-search-close',
                  'btn-close',
                  Styles['fa-clear'],
                  'font-size-20',
                  !state.searchName ? 'hide' : ''
                )}
              ></button>
            </div>
          </div>

          <div className={classNames(Styles['country-select-group'])}>
            <BsSelect
              options={[
                {
                  key: '0',
                  _name: $t('ResCarrierPIndex.global.__AllCountries')
                },
                ...filteredOptions
              ]}
              defaultOptionTitle={
                $t('ResGCountry.items').filter((item) => {
                  return item.key == state.searchCountry
                })?.[0]?.['_name'] || $t('ResCarrierPIndex.global.__AllCountries')
              }
              onSelectionChange={(value) => {
                handleQueryChange({ value: value, queryKey: 'c', stateKey: 'searchCountry' })
                // 切换国家需要页数重置
                setState({ currentPage: 1 })
              }}
              onInputChange={(value) => {
                setState({ ...state, filterText: value })
              }}
            ></BsSelect>
          </div>
        </div>
        <div className={classNames(Styles['yq-show-carrier'])} id="yq-show-carrier" aria-live="polite">
          <div className={classNames('no-gutters', 'row')}>
            {state.carriersArray.length > 0 ? (
              state.carriersArray?.map((item: AllCarriersInfoDTO) => {
                const carrierName = item[itemNameText] ? item[itemNameText] : item._name
                return (
                  <a
                    href={`/${locale}/carriers/${filterCarriersName(toFilterSpace(item._name))}`}
                    className="col-12 col-sm-4 col-md-3"
                    title={carrierName}
                    key={item.key}
                  >
                    <figure className="">
                      <div className={classNames(Styles['carriers-img-box'])}>
                        <div className="relative">
                          <CustomImage
                            loading="lazy"
                            width={64}
                            height={64}
                            className="lazy-image-fill"
                            isWebp={false}
                            src={`https://res.17track.net/asset/carrier/logo/120x120/${item.key}`}
                            alt={`${item._name}`}
                            errorSrc="https://res.17track.net/asset/carrier/logo/120x120/0"
                          />
                        </div>
                      </div>
                      <div>
                        <p className={classNames(Styles['icon-carrier-title'])}>{carrierName}</p>
                        {$t('ResGCountry.items').itemsDict.get(item._country_iso) ? (
                          <p className={classNames(Styles['icon-carrier-desc'], Styles['country'])}>
                            {$t('ResGCountry.items').itemsDict.get(item._country_iso)._name}
                          </p>
                        ) : (
                          ''
                        )}
                        <p className={classNames(Styles['icon-carrier-desc'])}>
                          {i18nFormatAll($t('ResCarrierPIndex.global.__tracking'), carrierName)}
                        </p>
                      </div>
                    </figure>
                  </a>
                )
              })
            ) : (
              <div className={Styles['no-data-box']}>
                <div className="relative">
                  <CustomImage
                    src="//res.17track.net/global-v2/imgs/illustration/160x160/no-data"
                    isWebp={false}
                    alt="no-data"
                    fill
                    className="lazy-image-fill"
                  />
                </div>
                <a href={`//www.17track.net/${locale}/about/contactus`}>{$t('ResCarrierPIndex.global.__NoCarrier')}</a>
              </div>
            )}
          </div>
          <CarriersPagination
            currentPage={state.currentPage}
            totalPages={Math.ceil(state.searchTotalNum / state.currentPageShowCarriers)}
            currentPageRange={4}
            onPageChange={handleOnPageChange}
          />
          <When condition={state.loadingState}>
            <div className={Styles['loading-wrapper']}>
              <div className={Styles.loading}></div>
              <div className={Styles['loading-text']}>Loading</div>
            </div>
          </When>
        </div>
      </section>
    </>
  )
}
