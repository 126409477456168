import { useSetState } from 'ahooks'
import { throttle } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import { DropDirection } from 'react-bootstrap/DropdownContext'
import useI18n from 'src/hooks/use_i18n'

interface State {
  selectedOptionKey: string | null
  dropDirection?: 'down' | 'up'
  maxListLength: number
  countryInputValue: string
}

interface props {
  options: any[]
  defaultOptionTitle?: string
  onInputChange?: (option: string) => void
  onSelectionChange?: (option: string) => void
}

export default function BsSelect({
  options = [],
  onInputChange = () => {},
  onSelectionChange = () => {},
  defaultOptionTitle = 'select option'
}: props) {
  const { $t } = useI18n()
  const [dorpState, setDorpState] = useState<DropDirection>('down')
  const [state, setState] = useSetState<State>({
    selectedOptionKey: '0', // 默认
    maxListLength: 6,
    countryInputValue: ''
  })

  const handleSelect = (eventKey: string | null) => {
    setState({ selectedOptionKey: eventKey, countryInputValue: '' })
    if (eventKey) {
      onSelectionChange(eventKey)
    }
  }

  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleScroll = throttle(() => {
    if (!dropdownRef.current) return

    const rect = dropdownRef.current.getBoundingClientRect()
    const viewportHeight = window.innerHeight

    // 检查下拉菜单下方是否有足够的空间打开,这个是切换上下按钮的，因为内置加了输入框所以这个要自己计算
    if (rect.bottom + 330 > viewportHeight) {
      setDorpState('up')
    } else {
      setDorpState('down')
    }
  }, 200)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
      handleScroll.cancel()
    }
  }, [])

  return (
    <>
      <ButtonGroup className="mb-3" size={'lg'} vertical ref={dropdownRef}>
        <DropdownButton
          id={`dropdown-button-drop-${dorpState}`}
          title={defaultOptionTitle}
          onSelect={handleSelect}
          drop={dorpState}
          className="d-inline"
          size={'lg'}
        >
          <Dropdown.Header>
            <input
              type="text"
              placeholder={$t('ResCarrierPIndex.global.__AllCountries')}
              onChange={(e) => {
                onInputChange(e.target.value)
                setState({ countryInputValue: e.target.value })
              }}
              value={state.countryInputValue}
              className="form-control mb-2"
            />
          </Dropdown.Header>
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            {options.map((option: any) => (
              <Dropdown.Item key={option.key} eventKey={option.key} active={option.key == state.selectedOptionKey}>
                {option._name}
              </Dropdown.Item>
            ))}
          </div>
        </DropdownButton>
      </ButtonGroup>
    </>
  )
}
