import classNames from 'classnames'
import { ReactNode } from 'react'
import Pagination from 'react-bootstrap/Pagination'
import { HandlePageEventProps } from 'src/types'
import Styles from './index.module.scss'

// 单独组件级定义
interface Props {
  currentPage: number | 1
  totalPages: number | 1
  currentPageRange: number | 4
  onPageChange: (props: HandlePageEventProps) => void
}

/**
 *@Description: 按照旧项目分页逻辑保持
 *@param {number} currentPage 当前页码数
 *@param {number} totalPages 总的页码数
 *@param {number} currentPageRange 默认当前页前后可预览页数
 *@param {function} onPageChange 切换页码数回调
 */
export default function CarriersPagination({ currentPage, totalPages, currentPageRange, onPageChange }: Props) {
  // 前置判断如果总页数为1不显示分页器, null react默认不显示节点
  if (totalPages <= 1) {
    return null
  }

  // 用于接收中间按钮模块的显示itemDom
  const pageNumbers: ReactNode[] = []

  // 当页数小于等于12: 2+currentPageRange+1+currentPageRange+2时，显示所有页码
  if (totalPages < 13) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          href={`/carriers/page-${i}`}
          onClick={(e) => onPageChange({ eventObject: e, page: i })}
        >
          {i}
        </Pagination.Item>
      )
    }
  } else {
    // 显示前两页
    pageNumbers.push(
      <Pagination.Item
        key={1}
        active={currentPage === 1}
        href={`/carriers/page-${1}`}
        onClick={(e) => onPageChange({ eventObject: e, page: 1 })}
      >
        1
      </Pagination.Item>
    )
    pageNumbers.push(
      <Pagination.Item
        key={2}
        active={currentPage === 2}
        href={`/carriers/page-${2}`}
        onClick={(e) => onPageChange({ eventObject: e, page: 2 })}
      >
        2
      </Pagination.Item>
    )

    // 显示前置省略号: 大于(2+currentPageRange+1+currentPageRange)/2
    if (currentPage > 7) {
      pageNumbers.push(<Pagination.Ellipsis key="ellipsis-start" />)
    }

    // 显示中间页码（当前页前后currentPageRange个页码）
    const startPage = Math.max(3, currentPage - currentPageRange)
    const endPage = Math.min(totalPages - 2, currentPage + currentPageRange)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          href={`/carriers/page-${i}`}
          onClick={(e) => onPageChange({ eventObject: e, page: i })}
        >
          {i}
        </Pagination.Item>
      )
    }

    // 显示后置省略号：小于（2+currentPageRange+1+currentPageRange）/2
    if (currentPage < totalPages - 6) {
      pageNumbers.push(<Pagination.Ellipsis key="ellipsis-end" />)
    }

    // 显示最后两页
    pageNumbers.push(
      <Pagination.Item
        key={totalPages - 1}
        active={currentPage === totalPages - 1}
        href={`/carriers/page-${totalPages - 1}`}
        onClick={(e) => onPageChange({ eventObject: e, page: totalPages - 1 })}
      >
        {totalPages - 1}
      </Pagination.Item>
    )
    pageNumbers.push(
      <Pagination.Item
        key={totalPages}
        active={currentPage === totalPages}
        href={`/carriers/page-${totalPages}`}
        onClick={(e) => onPageChange({ eventObject: e, page: totalPages })}
      >
        {totalPages}
      </Pagination.Item>
    )
  }

  return (
    <>
      <Pagination className={classNames(Styles['PaginationBox'], 'justify-content-md-center')}>
        <Pagination.Prev
          onClick={(e) => onPageChange({ eventObject: e, page: currentPage - 1 })}
          aria-label="prev"
          rel="prev"
          href={currentPage === 1 ? `/carriers/page-1` : `/carriers/page-${currentPage - 1}`}
          disabled={currentPage === 1}
        />
        {pageNumbers}
        <Pagination.Next
          onClick={(e) => onPageChange({ eventObject: e, page: currentPage + 1 })}
          aria-label="next"
          rel="next"
          href={currentPage === totalPages ? `/carriers/page-${currentPage}` : `/carriers/page-${currentPage + 1}`}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </>
  )
}
