import carriersConfig from '@/config/carriers/carriersConfig'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Banner from 'src/components/carriersPage/banner'
import JoinBanner from 'src/components/carriersPage/joinBanner'
import SearchCarriers from 'src/components/carriersPage/searchCarriers'
import Layout from 'src/components/layout'
import LinkSeo from 'src/components/layout/linkSeo'
import MetaSeo from 'src/components/layout/metaSeo'
import useGA from 'src/hooks/use_ga'
import useI18n from 'src/hooks/use_i18n'
import { AllCarriersInfoDTO, CarrierInfoMarkDTO } from 'src/types'
import { getArrayByCountryAndLanguage, numberFormat, sortCarrier } from 'src/utils/carriers_info'
import { getV5Front } from 'src/utils/server_global'
import { DEFAULT_CARRIER_INFO_MARK } from '../../constants/carrier_info_mark'

const logger = require('@/config/logger')

interface PageProps {
  currentPageJson: AllCarriersInfoDTO[]
  currentPage: number
  totalCarriersStr: number | string
  totalCarriersNum: number
  carrierInfoMark: CarrierInfoMarkDTO
  type: 'page' | 'index'
  page: number
  country: string | any
}

export default function CarriersInfo({
  currentPageJson,
  carrierInfoMark,
  currentPage,
  totalCarriersStr,
  totalCarriersNum,
  type,
  page,
  country
}: PageProps) {
  const { $t, activeLocale: locale, i18nFormatAll } = useI18n()
  const router = useRouter()
  const query = new URLSearchParams(router.asPath.split('?')[1])
  const queryPage = page || query.get('page')
  const seoTitle = queryPage
    ? `${$t('ResWwwPSeo.global.__seoCarriersTitle')} | ${carriersConfig.PAGE_LOCAL_MAP[locale]} ${queryPage} | 17TRACK `
    : `${$t('ResWwwPSeo.global.__seoCarriersTitle')} | 17TRACK`
  const seoDescription = i18nFormatAll($t('ResWwwPSeo.global.__seoCarriersDesc'), [
    carrierInfoMark?.partner,
    i18nFormatAll('{0}', [carrierInfoMark?.airwaybill])
  ])
  const seoKeyword = $t('ResWWWAndM.global.__seoKeyword')
  let seoJsonLd: string | any = ''
  if (type === 'index') {
    seoJsonLd = {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Homepage',
          item: `https://www.17track.net/${locale}`
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'Carriers',
          item: `https://www.17track.net/${locale}/carriers`
        }
      ]
    }
  } else if (type === 'page') {
    seoJsonLd = {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Homepage',
          item: `https://www.17track.net/${locale}/page-${page}`
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'Carriers',
          item: `https://www.17track.net/${locale}/carriers/page-${page}`
        }
      ]
    }
  }

  useGA('carriers-index')

  return (
    <>
      <MetaSeo title={seoTitle} description={seoDescription} keywords={seoKeyword} />
      <Head>
        {seoJsonLd && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(seoJsonLd)
            }}
          ></script>
        )}
      </Head>
      <LinkSeo TagType={`carriers_${type}`} TagTypeValue={page} routeLang={locale} />
      <Layout showAllPlatform carrierInfoMark={carrierInfoMark} country={country}>
        <div>
          <Banner totalCarriersStr={totalCarriersStr} />
          <SearchCarriers
            currentPageJson={currentPageJson}
            currentIdxPage={currentPage}
            totalCarriersNum={totalCarriersNum}
          />
          <JoinBanner />
        </div>
      </Layout>
    </>
  )
}

export async function getServerSideProps({ locale, req }) {
  try {
    // 目前只处理一级路由，二级,你所看到的nodePage和nodeLang是指由express直接转发渲染过来传递的数据
    const slug = req.queryParams?.nodePage || 'index'
    const lang = locale || req.queryParams?.nodeLang || 'en'
    // 绕过是走页数url即page, 运输商等二级文件路由走name, 首页index
    let type: 'page' | 'index'
    let page: number = 0
    const V5Front = await getV5Front(['carriers'], lang)
    const carrierInfoMark = await fetch(`https://res.17track.net/asset/carrier/info/indicator.${lang}.json`)
      .then((res) => res.json())
      .catch((error) => {
        logger.error('carriers页面获取carrierInfoMark信息异常', { message: error.message })
        return DEFAULT_CARRIER_INFO_MARK // 兜底数据
      })

    const allCarriersJson = await fetch(`https://res.17track.net/asset/carrier/info/carrier.all.json`)
      .then((res) => res.json())
      .catch((error) => {
        logger.error('carriers页面获取allCarriersJson数据异常', { message: error.message })
        return [] // 兜底无数据
      })

    if (!isNaN(Number(slug))) {
      type = 'page'
      page = slug
      // 获取分页数据
    } else if (slug === 'index') {
      type = 'index'
    } else {
      // 无逻辑路由重定向carriers首页
      return {
        redirect: {
          destination: `/${lang}/carriers`,
          permanent: false // or true, based on your requirement
        }
      }
    }
    const { 'cf-ipcountry': country } = req.headers
    const currentPage: number = Number(page) || 1
    // 根据搜索结果得到的运输商数组
    const _matchCarriers = sortCarrier(allCarriersJson, getArrayByCountryAndLanguage(country, lang))

    const _matchCarriersLen = _matchCarriers.length

    if (isNaN(currentPage) || currentPage < 0 || currentPage > Math.ceil(_matchCarriersLen / 24)) {
      return {
        redirect: {
          destination: `/${lang}/carriers`,
          permanent: false // or true, based on your requirement
        }
      }
    }

    logger.info(`carriers-${type}请求header：`, req.headers)
    // 服务端只取一页运输商数据
    const currentPageJson = _matchCarriers.slice(24 * (currentPage - 1), currentPage * 24)
    return {
      props: {
        locale: lang,
        V5Front,
        carrierInfoMark,
        currentPageJson,
        currentPage,
        totalCarriersStr: numberFormat(_matchCarriersLen),
        totalCarriersNum: _matchCarriersLen,
        type,
        page,
        country: country ?? 'CN'
      }
    }
  } catch (error: any) {
    logger.error('carriers页面请求异常', { message: error.message })
  }
}
